@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';

@font-face {
  font-family: 'Noto Sans CJK';
  font-style: normal;
  font-weight: 100;
  src: url("styles/fonts/NotoSans-Light.ttf") format('woff'),
}

@font-face {
  font-family: 'Noto Sans CJK';
  font-style: normal;
  font-weight: normal;
  src: url("styles/fonts/NotoSans-Regular.ttf") format('woff'),
}


@font-face {
  font-family: 'Noto Sans CJK';
  font-style: normal;
  font-weight: 500;
  src: url("styles/fonts/NotoSans-Medium.ttf") format('woff'),
}

@font-face {
  font-family: 'Noto Sans CJK';
  font-style: normal;
  font-weight: bold;
  src: url("styles/fonts/NotoSans-Bold.ttf") format('woff'),
}

/* Noto Sans Font */

*:not(.e-btn-icon, .e-icons){
  font-family: "Noto Sans KR", "Noto Sans", sans-serif !important;
  font-size: 1.0rem;
}

html {
  font-family: "Noto Sans KR", "Noto Sans", sans-serif;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* TreeView CSS */
/*apply custom css to first level*/
.DeptAndEmpTree .e-level-1 > .e-text-content .e-list-text{
  font-weight: bold;
  color: #971B2F;
}

/*apply custom css to second level*/
.DeptAndEmpTree .e-level-2 > .e-text-content .e-list-text {
  font-weight: bold;
  color: #222222;
}

/*apply custom css to all the leaf nodes*/
.DeptAndEmpTree .e-level-3 > .e-text-content .e-list-text {
  font-weight: bold;
  color: #444444;
}

.DeptAndEmpTree .e-level-4 > .e-text-content .e-list-text {
  font-weight: bold;
  color: #666666;
}